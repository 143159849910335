import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Entity} from './main.entity';
import {AppService} from '../services/app.service';
import {environment} from '../../environments/environment';
import {ApiResponse} from '../entities/apiResponse.entity';
import HttpClientUtils from './httpClientUtils';

@Injectable()
export abstract class MainModelService {

  public baseUrl = environment.baseUrl;
  public url: string | undefined;
  public data: Entity | undefined;

  protected apiUrl = environment.apiUrl;

  protected model: string | undefined;
  protected http: HttpClient;

  protected appService: AppService;

  constructor(
    http: HttpClient,
    appService: AppService
  ) {
    this.http = http;
    this.appService = appService;
  }

 async showLoading() {
    await this.appService.showLoading();
  }

  async hideLoading() {
    await this.appService.hideLoading();
  }

  async getRequest(
    data: any,
    callback: (answer: ApiResponse) => void,
    url: string | null = null,
    message: boolean = true,
    loading: boolean = true
  ) {

    if (loading) {
      await this.showLoading();
    }

    this.getServerRequest(this.getUrl(url), 'GET', data, loading).subscribe(res => {
      if (res) {
        this.handleSuccess(res, message, callback, loading);
      }
    }, (error) => {
      this.handleError(error, loading);
    });

  }

  async putRequest(
    data: any,
    getData: any,
    callback: (answer: ApiResponse) => void,
    url: string | null = null,
    message: boolean = true,
    loading: boolean = true
  ) {

    if (loading) {
      await this.showLoading();
    }

    this.postServerRequest(this.getUrl(url), 'PUT', data, getData, null, loading).subscribe(res => {
      if (res) {
        this.handleSuccess(res, message, callback, loading);
      }
    }, (error) => {
      this.handleError(error, loading);
    });

  }

  async deleteRequest(
    data: any,
    callback: (answer: ApiResponse) => void,
    url: string | null = null,
    message: boolean = true,
    loading: boolean = true
  ) {

    if (loading) {
      await this.showLoading();
    }

    this.getServerRequest(this.getUrl(url), 'DELETE', data, loading).subscribe(res => {
      if (res) {
        this.handleSuccess(res, message, callback, loading);
      }
    }, (error) => {
      this.handleError(error, loading);
    });
  }

  async postRequest(
    data: any,
    getData: any,
    callback: (answer: ApiResponse) => void,
    url: string | null = null,
    message: boolean = true,
    loading: boolean = true
  ): Promise<ApiResponse> {

    if (loading) {
      await this.showLoading();
    }

    return new Promise((resolve, reject) => {
      this.postServerRequest(this.getUrl(url), 'POST', data, getData, null, loading).subscribe(res => {
        if (res) {
          this.handleSuccess(res, message, callback, loading);
          resolve(res);
        }
      }, (error) => {
        this.handleError(error, loading);
        reject(error);
      });
    });
  }

  public getUrl(url: string | null = null): string {
    if (url && (url.includes('http://') || url.includes('https://'))) {
      return url;
    }
    return this.baseUrl + this.apiUrl + this.model + (url ? '/' + url : '');
  }

  public getServerRequest<T>(url: string, method: string = 'GET', data: any = {}, loading: boolean): Observable<ApiResponse | null> {

    const params = this.getParams(data);

    if (method === 'GET') {
      return this.http.get<ApiResponse>(url, {params})
        .pipe(
          catchError(this.handleDeepError(loading))
        );
    } else if (method === 'DELETE') {
      return this.http.delete<ApiResponse>(url, {params})
        .pipe(
          catchError(this.handleDeepError(loading))
        );
    }

    return of(null);
  }

  public postServerRequest(
    url: string,
    method: string = 'POST',
    data: any, getData: any = [],
    headers: any = null,
    loading: boolean = true
  ): Observable<ApiResponse | null> {


    const params = this.getParams(getData);
    const options = {params, headers};

    if (method === 'POST') {
      return this.http.post<ApiResponse>(url, data, options)
        .pipe(
          catchError(this.handleDeepError(loading))
        );
    } else if (method === 'PUT') {
      return this.http.put<ApiResponse>(url, data, options)
        .pipe(
          catchError(this.handleDeepError(loading))
        );
    }

    return of(null);
  }

  public async testRequest(callback: any) {
    await this.showLoading();

    setTimeout(() => {
      this.hideLoading();
      callback();
    }, 500);

  }

  public showAlert(title: string | null = null, desc: string | null = null, error = false) {
    this.appService.showAlert(title, desc, error);
  }

  public abortFileTransfer() {

  }

  public getParams(array: any) {
    return HttpClientUtils.toHttpParams(array);
  }

  private async handleError(error: any, loading: boolean) {
    if (typeof error !== 'string') {
      if (typeof error?.join === 'function') {
        error = error?.join('/r/n');
      }
    }
    if (loading) {
      await this.hideLoading();
    }
    // this.showAlert('Error', error, true);
  }

  private async handleSuccess(data: ApiResponse, showMessage: boolean, callback: (answer: ApiResponse) => void, loading: boolean) {
    if (loading) {
      await this.hideLoading();
    }

    if (showMessage && data.message) {
      this.showAlert(data.status ? 'Ok' : 'Error', data.message, !data.status);
    }

    callback(data);
  }

  private handleDeepError(loading) {
    return (error: any): Observable<ApiResponse | null> => {
      if (loading) {
        this.hideLoading();
      }

      // Обрабатываем только специфические случаи
      if (error?.status === 401) {
        console.warn('401 Unauthorized detected.');
        return of(null); // Возвращаем пустой Observable
      }

      // Обработка остальных ошибок
      if (!error.error?.text?.includes('string(0)')) {
        this.showAlert(error.message, error.error?.text, true);
      }

      // Пробрасываем ошибку выше
      return throwError(() => error);
    };
  }

}
