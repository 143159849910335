import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError, TimeoutError} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, timeout} from 'rxjs/operators';
import {AppService} from '../../services/app.service';
import { NavigationService } from 'src/app/services/navigation.service';
import {AuthService} from '../../services/auth.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private appService: AppService,
    private navigationService: NavigationService,
    private authService: AuthService,
  ) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      timeout(120000),
      catchError((err) => {
        const isHttpError = err instanceof HttpErrorResponse;
        const isUnauthorized = isHttpError && err.status === 401;

        if (isUnauthorized) {
          // Первая ошибка 401: выходим из приложения
          this.handleUnauthorizedError();
          return throwError({error: 'isUnauthorized', status: 401});
        }

        return throwError(err);
      })
    );
  }

  private handleUnauthorizedError() {
    this.appService.appReady = true;
    // Очистка данных пользователя, перенаправление на страницу авторизации
    this.authService.logout();
  }

}
